import _objectSpread from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import Reg from "./components/reg.vue";
import Ewm from "./components/Ewm.vue";
import LoginSon from "./components/loginson.vue";
import shortMessage from "./components/shortMessage.vue";
import forGet from './components/forgetpassword.vue';
import { mapActions } from "vuex";
import account from '@mixin/account';
export default {
  data: function data() {
    return {
      loginIndeShow: 1,
      //控制登录框显示隐藏//忘记密码
      currentComponent: {
        name: 'LoginSon',
        components: LoginSon
      },
      showDia: false,
      ShowRuler: false
    };
  },
  components: {
    Reg: Reg,
    Ewm: Ewm,
    LoginSon: LoginSon,
    shortMessage: shortMessage,
    forGet: forGet
  },
  mixins: [account],
  created: function created() {
    //清空购物车
    this.resetState();
  },
  mounted: function mounted() {
    this.targetCustomerLogin();
    // 自动刷新一次页面  首次手机号登录的组件没有加载
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['resetState'])), {}, {
    // 立即注册页面显示
    costPlannedAmountChange: function costPlannedAmountChange() {
      this.loginIndeShow = 2;
    },
    // 忘记与显示密码
    LostPwdSonLogin: function LostPwdSonLogin() {
      this.loginIndeShow = 3;
    },
    LostPassword: function LostPassword() {
      this.loginIndeShow = 1;
    },
    changeIndex: function changeIndex(value) {
      switch (value) {
        case 'Ewm':
          this.currentComponent.name = 'Ewm';
          this.currentComponent.components = Ewm;
          break;
        case 'LoginSon':
          this.currentComponent.name = 'LoginSon';
          this.currentComponent.components = LoginSon;
          break;
        case 'shortMessage':
          this.currentComponent.name = 'shortMessage';
          this.currentComponent.components = shortMessage;
          break;
        default:
          this.currentComponent.name = 'LoginSo';
          this.currentComponent.components = LoginSo;
          break;
      }
    }
  })
};