var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input-wrap"
  }, [_c('form', [_c('div', {
    staticClass: "inputBox"
  }, [!_vm.isCheckAccount ? _c('div', [_c('div', {
    staticClass: "field"
  }, [_c('p', [_vm._v("客户账号")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.account,
      expression: "account"
    }],
    ref: "account",
    attrs: {
      "placeholder": "请输入需要重置密码的客户帐号"
    },
    domProps: {
      "value": _vm.account
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.account = $event.target.value;
      }, function ($event) {
        _vm.isAccountError = false;
      }]
    }
  }), _vm.isAccountError ? _c('div', {
    staticClass: "tip"
  }, [_vm._v("请输入需要重置密码的客户帐号")]) : _vm._e()])])]) : _vm._e(), _vm.isCheckAccount ? _c('div', [_c('div', {
    staticClass: "field"
  }, [_c('p', [_vm._v("手机号")]), _c('div', {
    staticClass: "iconfont"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.accountInfo.phone,
      expression: "accountInfo.phone"
    }],
    staticClass: "phone",
    attrs: {
      "placeholder": "请输入手机号",
      "disabled": ""
    },
    domProps: {
      "value": _vm.accountInfo.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.accountInfo, "phone", $event.target.value);
      }
    }
  }), !_vm.accountInfo.checkPhone ? _c('div', {
    staticClass: "tip not-phone"
  }, [_vm._v("请联系对应普健商城业务员（陶秋萍）完善手机号")]) : _vm._e()])]), _c('div', {
    staticClass: "field"
  }, [_c('p', [_vm._v("短信验证码")]), _c('div', {
    staticClass: "iconfont"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.verifyCode,
      expression: "verifyCode"
    }],
    ref: "verifyCode",
    attrs: {
      "type": "text",
      "placeholder": "请输入验证码"
    },
    domProps: {
      "value": _vm.verifyCode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.verifyCode = $event.target.value;
      }
    }
  }), !_vm.showTime ? _c('span', {
    staticClass: "getMsg getMsgStyle",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.getVerifyCode();
      },
      "input": function input($event) {
        _vm.isCodeError = false;
      }
    }
  }, [_vm._v("获取验证码")]) : _vm._e(), _vm.showTime ? _c('span', {
    staticClass: "getMsg showTime"
  }, [_vm._v(_vm._s(_vm.time) + "s后重发")]) : _vm._e()]), _vm.isCodeError ? _c('div', {
    staticClass: "tip"
  }, [_vm._v("请输入验证码")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    staticClass: "login-btn",
    attrs: {
      "type": "is-info",
      "expanded": ""
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(_vm._s(_vm.isCheckAccount ? '重置密码' : '下一步') + " ")])], 1), _c('div', {
    staticClass: "register",
    on: {
      "click": _vm.LostPassword
    }
  }, [_vm._v("去登录")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };