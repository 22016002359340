var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "loginApp"
  }, [_vm.code == 1 || _vm.code == 10 || _vm.code == 4 ? _c('div', {
    staticClass: "qrs"
  }, [_vm.code == 10 ? _c('div', {
    staticClass: "qr"
  }, [_c('p', [_vm._v("二维码已失效")]), _vm.code == 10 ? _c('div', {
    staticClass: "qrText"
  }, [_c('button', {
    on: {
      "click": _vm.retry
    }
  }, [_vm._v("刷新")])]) : _vm._e()]) : _vm._e(), _c('vue-qr', {
    attrs: {
      "text": _vm.imgUrl,
      "size": 250,
      "logoSrc": _vm.logo,
      "logoScale": 0.2
    }
  })], 1) : _vm._e(), _vm.code == 1 || _vm.code == 4 ? _c('div', {
    staticClass: "errText"
  }, [_vm._v("打开手机"), _c('span', {
    staticStyle: {
      "color": "blue"
    }
  }, [_vm._v("普健医药")]), _vm._v("扫描二维码")]) : _vm._e(), _vm.code == 2 ? _c('div', {
    staticClass: "successful"
  }, [_vm._m(0), _c('div', {
    staticClass: "successfulText"
  }, [_vm._v("扫描成功!")])]) : _vm._e(), _vm.code == 2 ? _c('div', {
    staticClass: "successfulP"
  }, [_vm._v("请勿刷新本页面，按手机提示操作!")]) : _vm._e(), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/dl_chenggong.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "moreIcon"
  }, [_c('div', [_c('div', {
    staticClass: "iconAll"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets-v2/images/dl_mianshuru.png"),
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "explain"
  }, [_vm._v("免输入")])]), _c('div', [_c('div', {
    staticClass: "iconAll"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets-v2/images/dl_gengkuai.png"),
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "explain"
  }, [_vm._v("更快")])]), _c('div', [_c('div', {
    staticClass: "iconAll"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets-v2/images/dl_anquan.png"),
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "explain"
  }, [_vm._v("更安全")])])]);
}];
export { render, staticRenderFns };