import "core-js/modules/es.array.push.js";
export default {
  name: '',
  data: function data() {
    return {
      account: null,
      verifyCode: '',
      //短信验证码
      isAccountError: false,
      isPhoneError: false,
      isCodeError: false,
      accountInfo: null,
      isCheckAccount: false,
      //是否已经验证了帐号
      // 验证码显示  showTime: false,
      showTime: false,
      errCheck: false,
      // 验证码错误
      time: 90
    };
  },
  components: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 手机号验证
    getVerifyCode: function getVerifyCode() {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/common/forgetPwdTwo?account=".concat(this.account);
      this.$getHttpClient().post(url).then(function (res) {
        if (res.data.code == '200') {
          _this.showTime = true;
          //倒计时
          var that = _this;
          var timer = setInterval(function () {
            that.time--;
            if (that.time == 0) {
              clearInterval(timer);
              that.time = 90;
              _this.showTime = false;
            }
          }, 1000);
        } else {
          _this.$messageTip(res.data.msg, 'error');
        }
      });
    },
    // 注册并登录
    LostPassword: function LostPassword() {
      this.$emit('LostPwd', 1);
    },
    next: function next() {
      if (!this.isCheckAccount) {
        this.nextTap(); //先验证帐号
      } else {
        this.resetPwd();
      }
    },
    //下一步
    nextTap: function nextTap() {
      var _this2 = this;
      if (this.account == null || this.account.length == 0) {
        this.$refs.account.focus();
        this.isAccountError = true;
        return;
      }
      var url = "/pjyy-deepexi-user-center/api/v1/common/forgetPwdFirst?account=".concat(this.account);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == '200') {
          _this2.isCheckAccount = true;
          _this2.accountInfo = res.data.data;
          console.log(res.data.data);
        } else {
          _this2.$messageTip(res.data.msg, 'error');
        }
      });
    },
    resetPwd: function resetPwd() {
      var _this3 = this;
      if (!this.verifyCode) {
        //验证码
        this.$refs.verifyCode.focus();
        this.isCodeError = true;
        return;
      }
      var url = '/pjyy-deepexi-user-center/api/v1/common/forgetPwdThree';
      var params = {
        id: this.accountInfo.id,
        loginSource: 'PC',
        verifyCode: this.verifyCode
      };
      this.$getHttpClient().post(url, params).then(function (res) {
        if (res.data.code == '200') {
          _this3.$messageTip("重置密码成功", 'success');
          _this3.$router.push({
            name: 'v2-login'
          });
        } else {
          _this3.$messageTip(res.data.msg, 'error');
        }
      });
    }
  }
};