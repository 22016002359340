import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-g-bgi"
  }, [_vm.showDia && _vm.loginIndeShow == '2' ? _c('div', {
    staticClass: "dia"
  }, [_c('span', {
    staticClass: "iconfont icon-reeor",
    on: {
      "click": function click($event) {
        _vm.showDia = false;
      }
    }
  }), _vm._m(0)]) : _vm._e(), _vm.ShowRuler && _vm.loginIndeShow == '2' ? _c('div', {
    staticClass: "dia"
  }, [_c('span', {
    staticClass: "iconfont icon-reeor",
    on: {
      "click": function click($event) {
        _vm.ShowRuler = false;
      }
    }
  }), _vm._m(1)]) : _vm._e(), _c('div', {
    staticClass: "v2-g-r"
  }, [_c('div', {
    staticClass: "v2-g-log",
    style: {
      'padding': _vm.loginIndeShow == 2 ? '20px 0' : '30px 0'
    }
  }, [_c('img', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "src": require("../../assets-v2/images/logo.png")
    }
  })]), _vm.loginIndeShow == '1' ? [!_vm.isLoginWithPhoneNumber ? _c('div', {
    staticClass: "v2-g-navigation"
  }, [_c('div', {
    staticClass: "tab",
    class: {
      ative: _vm.currentComponent.name == 'LoginSon' ? 'ative' : ''
    },
    on: {
      "click": function click($event) {
        return _vm.changeIndex('LoginSon');
      }
    }
  }, [_vm._v(" 账号登录 ")]), _c('div', {
    staticClass: "tab",
    class: {
      ative: _vm.currentComponent.name == 'shortMessage' ? 'ative' : ''
    },
    on: {
      "click": function click($event) {
        return _vm.changeIndex('shortMessage');
      }
    }
  }, [_vm._v(" 手机号登录 ")]), _c('div', {
    staticClass: "tab",
    class: _vm.currentComponent.name == 'Ewm' ? 'ative' : '',
    on: {
      "click": function click($event) {
        return _vm.changeIndex('Ewm');
      }
    }
  }, [_vm._v(" 扫码登录 ")])]) : _vm.isLoginWithPhoneNumber ? _c('div', {
    staticClass: "v2-g-navigation"
  }, [_c('div', {
    staticClass: "tab",
    class: {
      ative: _vm.currentComponent.name == 'shortMessage' ? 'ative' : ''
    },
    on: {
      "click": function click($event) {
        return _vm.changeIndex('shortMessage');
      }
    }
  }, [_vm._v(" 手机号登录 ")]), _c('div', {
    staticClass: "tab",
    class: _vm.currentComponent.name == 'Ewm' ? 'ative' : '',
    on: {
      "click": function click($event) {
        return _vm.changeIndex('Ewm');
      }
    }
  }, [_vm._v(" 扫码登录 ")])]) : _vm._e(), _c('div', {
    staticClass: "v2-g-componentSwitcher"
  }, [_c('keep-alive', [_c(_vm.currentComponent.components, {
    tag: "component",
    on: {
      "change": function change($event) {
        return _vm.costPlannedAmountChange($event);
      },
      "LostPwdSon": function LostPwdSon($event) {
        return _vm.LostPwdSonLogin();
      }
    }
  })], 1)], 1)] : _vm.loginIndeShow == '2' ? [_c('Reg', {
    on: {
      "close": function close($event) {
        _vm.showDia = true;
      },
      "showFn": function showFn($event) {
        _vm.ShowRuler = true;
      }
    }
  })] : _c('forGet', {
    on: {
      "LostPwd": _vm.LostPassword
    }
  }), _vm._m(2)], 2), _vm._m(3)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "dia"
    }
  }, [_c('div', {
    staticClass: "rulerContent"
  }, [_c('h4', [_vm._v("普健医药用户服务协议")]), _c('p', {
    staticClass: "ruler-title"
  }, [_vm._v("欢迎阅读重庆普健医药有限公司《普健医药商城》服务条款协议(下称“本协议”)。本协议阐述之条款和 条件适用于您使用《普健医药商城》APP）所提供的，在互联网上进行贸易和交流的各种工具和服务(下称“服 务”)。本协议在《普健医药商城》用户接受注册时生效。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("您在成为《普健医药商城》用户前，必须仔细阅读并接受本协议中所述的所有规则、条款和条件，包括 因被提及而纳入的条款和条件。强烈建议您阅读本协议时，同时阅读本协议中提及的其他网页、附件所包 含的内容，因为其可能包含对作为《普健医药商城》用户的您适用的进一步条款和条件。")]), _c('p', {
    staticClass: "name pt"
  }, [_vm._v("一、用户注册：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．用户注册是指用户登陆《普健医药商城》网站，按要求填写相关信息并确认同意履行相关用户协议的过 程。用户因进行交易、获取有偿服务等而发生的所有应纳税赋由用户自行承担。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．本协议所指用户分为个人用户与企业用户：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)个人用户（买家）是指通过《普健医药商城》向企业用户购买药品、医疗器械、保健食品、化妆品等商 品的具有完全民事行为能力的自然人；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)企业用户（卖家）是指通过《普健医药商城》向个人用户以零售等方式从事药品、医疗器械、保健品、 化妆品销售的药店、医药公司及生产企业且必须是具有合法经营资格和相关经营许可的法人组织；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)限制民事行为能力与无民事行为能力的自然人、无经营或特定经营资格的组织不当注册为《普健医药 商城》用户进行交易的，其与《普健医药商城》之间的服务协议自始无效，一经发现，《普健医药商城》 有权立即停止与该用户的交易、注销该用户，并追究其使用《普健医药商城》“服务”的一切法律责任。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3．用户在此保证所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述， 且保证《普健医药商城》可以通过用户所填写的联系方式与用户取得联系。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．用户应根据《普健医药商城》对于相关服务的要求及时提供相应的身份证明等资料，否则《普健医药商 城》有权拒绝向该会员或用户提供相关服务")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5．用户承诺将及时更新其用户信息以维持该等信息的有效性。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("6．如果用户提供的资料或信息包含有不正确、不真实的信息，《普健医药商城》保留取消用户会员资格并 随时结束为该用户提供服务的权利。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("7．用户注册成功后将得到一个用户名和密码，用户凭用户名和密码享受《普健医药商城》向其会员用户提 供的商品和服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("8．用户将对用户名和密码安全负全部责任，并且用户对以其用户名进行的所有活动和事件负全责。用户有 权根据《普健医药商城》规定的程序修改密码。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("9．非经《普健医药商城》书面同意，用户名和密码不得擅自转让或者授权他人使用，否则用户应承担由此 造成的一切后果。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("10．用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通告《普健医药商城》。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("二、商品交易与平台服务：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．用户在《普健医药商城》交易商品时必须遵守以下条款。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．用户在使用《普健医药商城》服务时应遵守所有适用的中国法律、法规、条例和地方性法律的要求。用 户还必须确保遵守本协议及纳入本协议的所有其他条款和规则的规定。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3．用户通过《普健医药商城》网站订购商品，订单即为购买商品的申请或要约。但只有当卖家向用户发出 确认收到订单及送货确认内容的电子数据，才构成卖家对该订单的承诺，此时合同即告成立。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．商品价格和可获性都将在《普健医药商城》网站上标明，显示的每一项价格都包含了增值税（按照法定 税率）。送货费将另外结算，费用根据用户选择的送货方式的不同而异。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5．在已确认用户订单后如发生意外的情况，包括但不限于由于供应商提价，税额变化引起的价格变化，或 是由于网站的错误等造成价格变化等情况，卖家在及时通知用户后有权取消该订单。在卖家取消该订单之 前，用户亦有权决定是否取消订单。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("三、用户的权利和义务：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．用户有权拥有其在《普健医药商城》的用户名及密码，并随时登陆《普健医药商城》网站，同时用户可 以使用用户信息通过《普健医药商城》进行交易行为。但不得以任何形式转让或授权他人使用自己的《普 健医药商城》用户名。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．用户有权根据本协议的规定以及《普健医药商城》网站上发布的相关规则在《普健医药商城》网站上查 询商品信息、发表使用体验、参与商品讨论、邀请关注好友、上传商品图片、参加《普健医药商城》的有 关活动，以及享受《普健医药商城》提供的其它信息服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3．用户有义务在注册和交易时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮 政编码等内容的有效性及安全性，保证买卖双方可以通过上述联系方式进行联系。同时，用户也有义务在 相关资料实际变更时及时更新有关注册资料。用户保证不以他人资料在《普健医药商城》进行注册和交易 行为。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．用户应当保证在使用《普健医药商城》买卖商品过程中遵守诚实信用原则，不在交易过程中采取不正当 行为，不扰乱网上交易的正常秩序。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5．用户享有言论自由权利；并拥有适度修改、删除自己发表的文章的权利。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("6．用户保证其使用《普健医药商城》服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会 利用《普健医药商城》提供的服务进行存储、发布、传播如下信息和内容：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)违反国家法律、法规政策的任何内容（信息);")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)违反国家规定的政治宣传和/或新闻信息;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)涉及国家秘密和/或安全的信息;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(4)封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(5)博彩有奖、赌博游戏;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(6)违反国家民族和宗教政策的信息;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(7)妨碍互联网运行安全的信息;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(8)侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容;")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("7．用户同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包 括但不限于设置URL、BANNER链接等。用户承认《普健医药商城》有权在用户违反上述时有权终止向用 户提供服务并不于退还任何款项，因用户上述行为给《普健医药商城》造成损失的，用户应予赔偿。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("8．用户在发表使用体验、讨论图片等，除遵守本条款外，还应遵守该讨论区的相关规定。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("9．未经《普健医药商城》同意，禁止用户在网站发布任何形式的广告。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("四、《普健医药商城》的权利和义务：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1.《普健医药商城》应根据用户选择的服务以及交纳款项的情况向用户提供合格的网络技术和信息服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.《普健医药商城》承诺对用户资料采取对外保密措施，不向第三方披露用户资料，不授权第三方使用用 户资料，除非：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)依据本协议条款或者用户与《普健医药商城》之间其他服务协议、合同、在线条款等规定可以提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)依据法律、法规的规定应当提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)行政、司法等有权部门要求《普健医药商城》提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(4)用户同意《普健医药商城》向第三方提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(5)《普健医药商城》解决举报事件、提起诉讼而提交的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(6)《普健医药商城》为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(7)《普健医药商城》为向用户提供产品、服务、信息而向第三方提供的，包括《普健医药商城》通过第三 方的技术及服务向用户提供产品、服务、信息的情况。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.《普健医药商城》有权使用用户资料。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4.《普健医药商城》有权利对用户进行审查并决定是否接受用户成为《普健医药商城》经营企业会员或是 否与用户进行某一交易。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5.《普健医药商城》保留在用户违反国家、地方法律、法规规定或违反本在线注册条款的情况下终止为用 户提供服务并终止用户账号的权利，并且在任何情况下，《普健医药商城》对任何间接、偶然、特殊及继 起的损害不负责任。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("五、违约责任：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．如果商家因为定价错误、商品缺货、商品描述错误给消费者带来不便，商家应立即对上述情况进行调整 修改并向消费者致歉。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．用户存在下列行为的，视为违约，《普健医药商城》有权做出相应处理：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)《普健医药商城》有权对用户的注册数据及交易行为进行查阅，发现注册数据或交易行为中存在任何问 题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)经国家生效法律文书或行政处罚决定确认用户存在违法行为，或者《普健医药商城》有足够事实依据可 以认定用户存在违法或违反服务协议行为的，《普健医药商城》有权在网站上以网络发布形式公布用户的 违法行为；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)对于用户在《普健医药商城》发布的下列各类信息，《普健医药商城》有权在不通知用户的前提下进行 删除或采取其它限制性措施。包括以炒作信用为目的的信息；《普健医药商城》有理由相信存在欺诈等恶 意或虚假内容的信息；《普健医药商城》有理由相信存在与网上交易无关或不是以交易为目的的信息；《普 健医药商城》有理由相信存在试图扰乱正常交易秩序因素的信息；《普健医药商城》有理由相信该信息违 反公共利益或可能严重损害《普健医药商城》和其它用户合法利益的。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("六、风险承担与规则：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．如果买家有证据证明因卖家产品信息的真实性使买家利益受到损失，卖家将对此承担责任；如果是由于 买家的错误理解导致利益受到损失，卖家将不承担责任。《普健医药商城》作为第三方有义务对双方的纠 纷进行积极的协调，并根据调查结果对用户进行相应的处理。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．如果交易产品质量存在问题，给买家利益造成损失，卖家将按照退换货规则承担责任。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3．交易达成后，在产品的配送过程中，产品出现毁损、灭失的，与买家无关。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．网络安全问题导致买家利益受到损失，《普健医药商城》不承担损失赔偿责任，但有义务配合买家进行 查询沟通。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5．除上述风险承担条款以外，具体的送货、退换货、补货及质量纠纷处理规则等《普健医药商城》将另行 公布。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("6．责任限制")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("除了《普健医药商城》的使用规则中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内， 对于因平台运营而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害， 或任何其它性质的损害，《普健医药商城》、《普健医药商城》的董事、管理人员、雇员、代理或其它代 表在任何情况下都不承担责任。不论是合同、保证、侵权（包括过失）项下的还是任何其它的责任，均为 买卖双方因交易而引起的纠纷，与第三方平台《普健医药商城》无关。这些责任排除和限制条款将在法律 所允许的最大限度内适用，并在用户资格被撤销或终止后仍继续有效。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("七、服务的中断和终止：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．如用户向《普健医药商城》提出注销注册用户身份时，经《普健医药商城》审核同意，由《普健医药商 城》注销该注册用户，用户即解除与《普健医药商城》的服务协议关系。但注销该用户账号后，《普健医 药商城》仍保留下列权利：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)用户注销后，《普健医药商城》有权保留该用户的注册数据及以前的交易行为记录；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)用户注销后，如用户在注销前在《普健医药商城》交易平台上存在违法行为或违反合同的行为，《普 健医药商城》仍可行使本服务协议所规定的权利。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．在下列情况下，《普健医药商城》可以通过注销用户的方式终止服务：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)在用户违反本服务协议相关规定时，《普健医药商城》有权终止向该用户提供服务。《普健医药商城》 将在中断服务时通知用户。但如该用户在《普健医药商城》终止提供服务后，再一次直接或间接或以他人 名义注册为《普健医药商城》用户的，《普健医药商城》有权再次单方面终止向该用户提供服务；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)如《普健医药商城》通过用户提供的信息与用户联系时，发现用户在注册时填写的电子邮箱已不存在或 无法接收电子邮件的，经《普健医药商城》以其它联系方式通知用户更改，而用户在三个工作日内仍未能 提供新的电子邮箱地址的，《普健医药商城》有权终止向该用户提供服务；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)一经《普健医药商城》发现用户注册数据中主要内容是虚假的，《普健医药商城》有权随时终止向该用 户提供服务；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(4)本服务协议终止或更新时，用户明示不愿接受新的服务协议的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(5)其它《普健医药商城》认为需终止服务的情况。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("八、适用的法律和管辖权：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("用户和《普健医药商城》之间的服务协议将适用中华人民共和国的法律，管辖地为当地人民法院。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("九、知识产权：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1.《普健医药商城》网站上的图表、标识、网页页眉、按钮图标、文字、服务品名等标示在网站上的信息 都是重庆普健医药有限公司的财产，受中国和国际知识产权法的保护。未经《普健医药商城》许可，用户 不得以任何可能引起消费者混淆的方式或任何贬低或诽谤重庆普健医药有限公司的方式用于与非《普健医 药商城》的任何产品或服务上。在《普健医药商城》网站上出现的不属于上述主体的所有其他商标是其商 标权利人各自的财产，这些权利人可能是也可能不是与重庆普健医药有限公司相关联、相联系或由重庆普 健医药有限公司的关联公司赞助的。未经重庆普健医药有限公司或相关商标所有人的书面许可，《普健医 药商城》网站上的任何东西都不应被解释为以默许或其他方式授予许可或使用网站上出现的商标的权利。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.《普健医药商城》用户发表的文章仅代表作者本人观点，与《普健医药商城》立场无关。作者文责自负。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.《普健医药商城》有权将在本网站发表的产品使用体验、产品讨论或图片自行使用或者与其他人合作使 用于其他用途，包括但不限于网站、电子杂志、杂志、刊物等，使用时需为作者署名，以发表文章时注明 的署名为准。文章有附带版权声明者除外。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．用户在《普健医药商城》网站上发表的文章及图片（包括转贴的文章及图片）版权仅归原作者所有，若 作者有版权声明或原作从其它网站转载而附带有原版权声明者，其版权归属以附带声明为准。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5．任何转载、引用发表于《普健医药商城》的版权文章须符合以下规范：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1)用于非商业、非盈利、非广告性目的时需注明作者及文章及图片的出处为\"《普健医药商城》\"；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2)用于商业、盈利、广告性目的时需征得文章或图片原作者的同意，并注明作者姓名、授权范围及原作出 处\"《普健医药商城》\"；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3)任何文章或图片的修改或删除均应保持作者原意并征求原作者同意，并注明授权范围。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("十、不承诺担保和责任限制")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．除非另有明确的书面说明，《普健医药商城》网站、目录及其所包含的或以其它方式通过本网站提供给 用户的全部信息、内容、材料、商品（包括软件）和服务，是由重庆普健医药有限公司在\"按现状\"和\"按现 有\"的基础上提供的。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．除非另有明确的书面说明,重庆普健医药有限公司不对本网站的运营及其包含在本网站上的信息、内容、 材料、产品(包括软件)或服务作任何形式的、明示或默示的声明或担保（除根据中华人民共和国法律规定 的以外）。用户明确同意自担风险使用本网站。在适用法律所允许的最大限度内，重庆普健医药有限公司 及其关联公司不承诺所有明示或默示的担保，包括但不限于对适销性和满足特定目的的默示担保。重庆普 健医药有限公司及其关联公司不担保《普健医药商城》网站、其所包含的或以其它方式通过本网站提供给 您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从《普健医药商城》网站发出的电子 信件没有病毒或其他有害成分。除非另有明确的书面说明，重庆普健医药有限公司不对由于使用《普健医 药商城》网站、或由于其所包含的或以其它方式通过本网站提供给用户的全部信息、内容、材料、产品（包 括软件）和服务、或购买和使用产品引起的任何损害承担责任（除非根据中华人民共和国法律应承担责任 的以外），包括但不限于直接、间接或附带的惩罚性和结果性的损害赔偿。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("十一、隐私：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("普健医药商城非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，您同意普健医药商 城按照在普健医药商城平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。如普健医 药商城平台网站或客户端未设独立隐私权政策但使用了普健医药商城平台账号登陆相应网站或客户端的， 为保护您的隐私权，我们将参照适用普健医药商城隐私权政策的要求对您的个人信息进行收集、存储、使 用、披露和保护。普健医药商城希望通过隐私权政策向您清楚地介绍普健医药商城对您个人信息的处理方 式，因此普健医药商城建议您完整地阅读《普健医药商城隐私权政策》，以帮助您更好地保护您的隐私权。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("十二条附则：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1．本注册条款中有关条款若被权威机构认定为无效，不应影响其他条款的效力，也不影响本服务条款的解 释、违约责任及争议解决的有关约定的效力。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2．一方变更通知、通讯地址或其他联系方式，应自变更之日起及时将变更后的地址、联系方式通知另一方， 否则变更方应对此造成的一切后果承担责任。用户同意，《普健医药商城》的有关通知只需在《普健医药 商城》有关网页上发布即视为送达用户。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3．因《普健医药商城》上市、被收购、与第三方合并、名称变更等事由，用户同意《普健医药商城》可以 将其权利和/或义务转让给相应的《普健医药商城》权利/义务的承受者。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4．用户在此再次保证已经完全阅读并理解了上述会员注册条款并自愿正式进入《普健医药商城》入驻会员 在线注册程序，接受上述所有条款的约束。")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "dia"
    }
  }, [_c('div', {
    staticClass: "rulerContent"
  }, [_c('h4', [_vm._v("普健医药商城隐私政策")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("更新日期：2022年9月7日")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("生效日期：2022年9月7日")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("引言")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 普健医药商城（以下简称为“我们”）尊重并保护所有用户的个人信息及隐私安全。为同时给您提供更准确、更安全的互联网环境，我们依据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国民法典》《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与/或服务（以下统称“使用我们的产品与/或服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 请在使用/继续使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为， 都表示您充分理解和同意本《普健医药商城用户隐私政策》（包括更新版本）的全部内容。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("关于我们")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("普健医药商城网站及APP的经营者为重庆普健医药有限公司。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("注册地址为重庆市南岸区玉马路8号D栋4楼。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("目录")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("一、我们如何收集和使用您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("二、我们如何共享、转让、公开披露您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("三、我们如何存储和保护您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("四、您如何管理您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("五、您如何注销您的账号")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("六、有关第三方提供产品和/或服务的特别说明")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("七、我们如何使用Cookie和其他同类技术")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("八、未成年人保护")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("九、我们如何更新隐私政策")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("十、如何联系我们")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("十一、其他")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("一、我们如何收集和使用您的个人信息")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("您理解并同意：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途， 或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知， 并在使用前再次征得您的同意。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("1.账号注册及登录")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1.个人信息搜集")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("您须授权我们收集和使用您个人信息的情形")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们仅会出于以下目的，收集和使用您的个人信息：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.本隐私政策中涉及的个人信息包括：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.1 基本信息（姓名、联系方式、微信号、QQ号、邮箱、收货地址）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.3 网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.4 个人财产信息（交易和消费记录、以及余额、充值余额、金币、优惠券、红包、账期等虚拟财产信息）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.5 个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.6 企业信息（企业名称、法人、营业执照）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.7 个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括： ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.8 您的财产信息（包括交易和消费记录、以及余额、充值余额、金币、优惠券、红包、账期等虚拟财产信息）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.9 网络身份识别信息（包括账号、邮箱地址及与前述有关的密码口令的组合）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2.10 其他信息（网页浏览记录、精准定位信息）；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 服务日志信息是您在访问我们及/或使用我们服务过程中，我们自动收集和记录的您的详细使用情况，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、分享信息、发布信息，以及IP地址、浏览器的类型、访问日期和时间； ")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("3.场景范围：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.1 基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能。我们访问您的相机是为了使您可以使用摄像头拍摄影像、照片，用于用户登录、直接拍摄并上传视频、图片参与UGC相关功能，进行相应内容在我们平台的展示，以及特定场景下经您授权活动参与，以及其他您明确知悉的场景。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.2 基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频以及本地缓存的视音频文件，确保本地图片、视频上传功能可行，便于您进行头像更换、发表和分享评论、保存活动分享二维码、或与客服沟通时证明您所遇到的问题。我们可能会通过您所上传的照片/图片来识别您需要参与的活动链接或者视频服务链接，或使用包含您所上传照片或图片的评论信息。 为实现您的好友、活动优惠以及其他链接分享需求， 我们可能会在设备本地读取您最近一次主动保存的图片链接地址。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.3 基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后实现音频录制功能；请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制音频时通过麦克风获取语音信息。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.4 位置权限：您可以打开您的位置权限，获取您所在地，进行服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.5 电话状态权限：为保障您的账号与使用安全，您需要授权我们读取本机识别码。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3.6 访问App安装列表：如您分享，需要访问APP安装列表来判断是否已安装微信/QQ等客户端。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您理解并同意，上述附加服务可能需要您在您的设备中开启您的摄像头（相机）、相册（图片库）、麦克风（语音）、位置及/或电话状态的访问权限，以实现这些权限所涉及信息的收集和使用。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。 ")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("2.实现网上购物所必须的功能")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（1）用户注册")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您首先需要注册一个普健医药商城账户成为普健医药商城用户。当您注册时，您需要至少向我们提供您准备使用的普健医药商城账户名、密码、收货地址、您本人的手机号码，并且我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("其后，您需要向我们提供所在企业的企业名称、法人、营业执照及相应的药品经营许可资质或其他证件，以便我们核实您是否具有对相关产品的经营资质。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（2）商品信息展示和搜索")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 在您使用我们服务过程中，为向您提供您所在位置可销售的商品信息及其他服务的展示和搜索服务，让您快速地找到您所需要的商品，我们会收集和使用您在访问或使用我们的产品/服务时的设备信息（包括IP地址、设备名称、设备型号、设备识别码和应用程序版本、分辨率、服务提供商网络ID（PLMN））、浏览器类型、服务日志信息，以及其他取得您授权的信息（比如您提供的地址信息）。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息，获取设备信息详见《我们如何使用 Cookies/SDK和同类技术》。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您也可以通过搜索来找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。未经您的明确同意，我们不会将您的相关个人信息提供给第三方。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("同时，我们通过以下措施努力保障您的隐私体验：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1) 如您要删除您的浏览和搜索记录，您可以 在“搜索框-历史搜索”中选择删除；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2) 如果您不想接受我们给您发送的商业性信息， 您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（3）下单")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 当您准备对您购物车内的商品进行结算时，普健医药商城系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址、配送方式（自提、配送、第三方物流）、开票方式以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息您应支付的货款金额及支付方式； ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；为确保支付环境安全，我们还会获取您的相关设备信息（包括IP地址、设备名称、设备型号、设备识别码和应用程序版本、分辨率、服务提供商网络ID（PLMN））、订单信息来判断您的交易是否存在异常以保护您的交易安全。 ")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（4）支付功能")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 在您下单后，您可以选择普健医药商城的关联方或与普健医药商城合作的第三方支付机构（包括支付宝支付、微信支付等通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的普健医药商城订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。 ")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（5）交付产品与/或服务功能")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 在当您下单并选择货到付款、账期付款或在线完成支付后，普健医药商城的关联方或与普健医药商城合作的第三方配送公司（包括京东、邮政等，以下称“配送公司”）将为您完成订单的交付。您知晓并同意普健医药商城的关联方或与普健医药商城合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。 我们的配送员在为您提供配送服务的同时，基于某些业务法律要求实名认证的需求，会协助您完成实名认证，如您购买手机号卡服务时。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("（6）客服与售后功能")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们的电话客服和售后功能会使用您的账号信息和订单信息。 为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容(包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("3.保障交易安全所必须的功能")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(一)关于风险链接")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的订单信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于普健医药商城系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(二)您可自主选择提供的个人信息的情形")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 1、为使您购物更便捷或更有乐趣，从而提升您在普健医药商城的网上购物体验，我们的以下扩展功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用这些可以为您所带来购物乐趣的扩展功能或在购买某些商品时需要重复填写一些信息。这些扩展功能包括： ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（1）基于摄像头（相机）的扩展功能：您可以使用这个扩展功能完成视频拍摄、拍照、扫码等功能。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（2）基于图片上传的扩展功能：您可以在普健医药商城上传您的照片来实现资质上传、商品求购及订单评价功能，我们会使用您所上传的图片或视频来识别您需要从产品或服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（3）基于服务的推送功能：我们会根据您的订单物流、订单售后、建议反馈等信息，通过消息通知告知处理进度及结果。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(4) 基于应用程序存储功能：应用程序储存空间 允许应用程序检索修改其代码、数据和缓存大小、对公账号及保存。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、上述扩展功能可能需要您在您的设备中向我们开启您的相机（摄像头）、相册（图片库）、麦克风以及服务推送的访问权限，以实现这些功能所涉及的信息的收集和使用。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您可以在手机自带系统通过“设置—应用与服务—权限管理”中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、与国家安全、国防安全有关的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、与公共安全、公共卫生、重大公共利益有关的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、与犯罪侦查、起诉、审判和判决执行等有关的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("5、所收集的个人信息是个人信息主体或监护人自行向社会公众公开的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("7、根据您的要求签订合同所必需的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("9、为合法的新闻报道所必需的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("11、法律法规规定的其他情形；")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("二、我们如何使用 Cookies、SDK和同类技术")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、为实现您联机体验的需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您购物车中的商品等数据帮助判断您的登录状态以及账户或数据安全。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问普健医药网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、关于SDK使用，我们产品集成阿里巴巴支付宝、微信SDK等第三方服务，采集设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)，用于唯一标识设备，以便向目标设备推送消息，统计分析服务。采集地理位置甄别推送通道，提高消息推送的区域覆盖率，提供基础反作弊能力。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("三、我们如何共享、转让、公开披露您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、共享")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、我们不会与普健医药以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（1）事先获得您明确的同意或授权；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（3）在法律法规允许的范围内，为维护普健医药、普健医药的关联方或合作伙伴、您或其他普健医药用户或社会公众利益、财产或安全免遭损害而有必要提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（4） 只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（5）应您需求为您处理您与他人的纠纷或争议；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（7）基于学术研究而使用；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（8）基于符合法律法规的社会公共利益而使用。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 4、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护普健医药、您或其他普健医药客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、转让")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、事先获得您明确的同意或授权；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、公开披露")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。 ")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("四、我们如何保护和保存您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们保护您个人信息的技术与措施 我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、数据安全技术措施")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("普健医药的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。 普健医药采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA1在内多种数据脱敏技术增强个人信息在使用中安全性。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("普健医药采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、普健医药为保护个人信息采取的其他安全措施")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、建议使用复杂密码")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("4、联系我们")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即与我们取得联系，以便我们采取相应措施。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("五、您如何管理您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("普健医药非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("1、访问和更正您的个人信息")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您的账户信息：PC端您可以在“普健医药”页面的“个人中心”菜单中查阅您提交给普健医药的所有个人信息，你也可通过上述途径更新除资质认证信息之外的其他个人信息，如您需要变更您的资质认证信息，您可拨打023-8860 9999服务热线申请变更。移动端具体路径为：首页—我的—设置修改账号名称、个人资料、电话号码等相关信息。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您的订单信息：PC端您可以通过访问“个人中心”--“全部订单”页面查看您的所有已经完成、待付款或待售后的订单。移动端具体路径为：移动端首页--“我的”--我的订单/待收货/待发货/已完成/已取消。您可以选择删除已经完成和已取消的订单来删除您的订单信息，但这样可能导致我们无法准确判断您的购买信息而难以提供相应的售后服务。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、自主管理控制营销信息及定向推送")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("消息推送――如您不希望收到App页面或者应用程序上的消息通知，可以移动端操作系统中的“消息-消息设置”中关掉对应的通知功能。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("推广/回访——如您不希望接收我们的推广短信或电话回访，可通过信息中相应的退订功能进行退订，或者在电话回访中明确表示拒绝。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("3、注销账户")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(1) 如果您仍需要注销账户，您的账户需同时满足以下条件：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("a)账户已结清历史订单金额，无欠款；账户内无未完成的订单和服务；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("b)账户无正在进行中的投诉举报或被投诉举报；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("c)账户已经解除与其他网站、其他APP的授权登录或绑定关系；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("d)账户当前为有效账户，非冻结状态；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(2) 普健医药账户一旦被注销将不可恢复，请您提前务必保存好相关订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(3) 注销普健医药账户，您将无法再使用本普健医药账户，也无法找回您普健医药账户中及与账户中相关的任何内容或信息，包括但不限于：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("a)您将无法登录、使用本普健医药账户。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("b)本普健医药账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录等）都将无法找回。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("c)您历史已获得的优惠券、VIP权益会员卡、等视为您自行放弃，将无法继续使用。您理解并同意，注销后，普健医药无法帮助您重新恢复以上服务。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(4) 注销本普健医药账户并不代表本普健医药账户注销前的账户行为和相关责任得到豁免或减轻。同时，在账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，合纵普健医药有权自行终止本账户的注销而无需另行得到您的同意。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(5) 注销普健医药账户途径，可通过拨打官方热线023-88609999，联系客服人员进行注销，我们将在7个工作日之内为您处理。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("六、通知和修订")]), _c('p', {
    staticClass: "name"
  }, [_vm._v(" 1、为给您提供更好的服务以及随着普健医药业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在普健医药网站、普健医药移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问普健医药以便及时了解最新的隐私政策。 ")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。 本隐私政策所指的重大变更包括但不限于：")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（3）个人信息共享、转让或公开披露的主要对象发生变化；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("(6)个人信息安全影响评估报告表明存在高风险时。")]), _c('p', {
    staticClass: "ruler-title pt"
  }, [_vm._v("七、如何联系我们")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系拨打023-88609999与我们取得联系。")]), _c('p', {
    staticClass: "name"
  }, [_vm._v("公司名称：重庆普健医药有限公司")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "promptBox"
  }, [_c('div', {
    staticClass: "promptBox-one"
  }, [_vm._v("后续取消“账号登录”")]), _c('div', {
    staticClass: "promptBox-two"
  }, [_vm._v("建议使用"), _c('span', [_vm._v("“手机号登录”")]), _vm._v("，更安全，更便捷，更省心！")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-login-bottom"
  }, [_c('div', {
    staticClass: "v2-g-flex-row v2-bottom3 v2-g-flex-space-between",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_c('div', [_vm._v(" 互联网药品信息服务资格证书:(渝)-经营性-2018-0002 ")]), _c('div', {
    staticClass: "v2-g-flex-column v2-g-flex-space-between",
    staticStyle: {
      "padding": "0 30px"
    }
  }, [_c('div', [_c('a', {
    staticStyle: {
      "color": "#ffffff"
    },
    attrs: {
      "href": "https://beian.miit.gov.cn/#/Integrated/index",
      "target": "_blank"
    }
  }, [_vm._v("渝ICP备18008873号-3")]), _vm._v("; 监管机构： 国家食品药品监管局 重庆市食品药品监管局51La")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/ga.png")
    }
  }), _c('a', {
    staticStyle: {
      "color": "#ffffff"
    },
    attrs: {
      "href": "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802004051",
      "target": "_blank"
    }
  }, [_vm._v("渝公网安备 50010802004051号")])])]), _c('div', {
    staticStyle: {
      "margin": "11px 0"
    }
  }, [_vm._v("公司地址：重庆市南岸区玉马路8号B栋")]), _c('div', {
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_vm._v("Copyright ©2023 www.pujian.com All rights reserved.")])]);
}];
export { render, staticRenderFns };