import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "input-wrap"
  }, [_c('div', {
    staticClass: "inputBox"
  }, [_c('p', [_vm._v("名称")]), _c('div', {
    staticClass: "iconfont"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.appellative,
      expression: "userInfo.appellative"
    }],
    ref: "appellative",
    attrs: {
      "placeholder": "输入名称"
    },
    domProps: {
      "value": _vm.userInfo.appellative
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "appellative", $event.target.value);
      }
    }
  })]), _c('p', [_vm._v("所在地区")]), _c('div', {
    staticClass: "iconfont regions"
  }, [_c('Cascader', {
    attrs: {
      "options": _vm.options,
      "defaultValue": _vm.defaultValue
    },
    on: {
      "addressdata": _vm.getAddressdata
    }
  })], 1), _c('p', [_vm._v("手机号")]), _c('div', {
    staticClass: "iconfont"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.phone,
      expression: "userInfo.phone"
    }],
    ref: "phone",
    attrs: {
      "placeholder": "请输入手机号"
    },
    domProps: {
      "value": _vm.userInfo.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "phone", $event.target.value);
      }
    }
  })]), _c('p', [_vm._v("短信验证码")]), _c('div', {
    staticClass: "iconfont",
    attrs: {
      "id": "password-2"
    }
  }, [_c('div', {
    staticClass: "checkCode"
  }, [!_vm.showTime ? _c('span', {
    staticClass: "getMsg getMsgStyle",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.getVerifyCode();
      }
    }
  }, [_vm._v("获取验证码")]) : _vm._e(), _vm.showTime ? _c('span', {
    staticClass: "getMsg showTime"
  }, [_vm._v(_vm._s(_vm.time) + "s后重新获取")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.verifyCode,
      expression: "userInfo.verifyCode"
    }],
    ref: "verifyCode",
    attrs: {
      "type": "text",
      "placeholder": "请输入验证码"
    },
    domProps: {
      "value": _vm.userInfo.verifyCode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "verifyCode", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "register-login"
  }, [_c('div', {
    staticClass: "protocal"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remember,
      expression: "remember"
    }],
    attrs: {
      "type": "checkbox",
      "checked": "checked"
    },
    domProps: {
      "checked": Array.isArray(_vm.remember) ? _vm._i(_vm.remember, null) > -1 : _vm.remember
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.remember,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.remember = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.remember = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.remember = $$c;
        }
      }
    }
  }), _c('div', {
    staticClass: "fieldText"
  }, [_vm._v(" 阅读并同意"), _c('span', {
    staticStyle: {
      "color": "#3875F6 !important"
    },
    on: {
      "click": _vm.showFn
    }
  }, [_vm._v("《用户协议》")]), _vm._v("和 "), _c('span', {
    staticStyle: {
      "color": "#3875F6"
    },
    on: {
      "click": _vm.Privacy
    }
  }, [_vm._v("《隐私政策》")])])]), _c('div', {
    staticClass: "login-btn-wrap"
  }, [_c('b-button', {
    staticClass: "login-btn",
    attrs: {
      "type": "is-info",
      "expanded": ""
    },
    on: {
      "click": _vm.registAction
    }
  }, [_vm._v("注册并进行登录 ")])], 1), _c('div', {
    staticClass: "enroll",
    on: {
      "click": _vm.loginIndexShows
    }
  }, [_vm._v("已有帐号,去登录")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };